<!--
 * @Description: 路内停车管理系统 绩效管理 考勤记录 attendanceRecord
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="巡检员编号">
              <el-input v-model="searchForm.userCode"
                        clearable
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="巡检员姓名">
              <el-input v-model="searchForm.userName"
                        clearable
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="巡检组">
              <el-select v-model="searchForm.inspectionGroupId"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in inspectionList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="签到状态">
              <el-select v-model="searchForm.signInStatusCode"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in options"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topSecondLine">
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="交班/签退状态">
              <el-select v-model="searchForm.signOutStatusCode"
                         clearable
                         placeholder="请选择">
                <el-option v-for="item in options2"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="签到时间">
              <el-date-picker v-model="signInTime"
                              clearable
                              type="daterange"
                              align="right"
                              unlink-panels
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="110px"
                          label="交班/签退时间">
              <el-date-picker v-model="signOutTime"
                              clearable
                              type="daterange"
                              align="right"
                              unlink-panels
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="topButton">
        <el-button type="primary"
                   @click="onSubmit">查 询</el-button>
        <el-button type="info"
                   style="margin-left: 17px"
                   @click="resetForm">重 置</el-button>
      </el-row>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>考勤记录</span>
        <el-button type="info"
                   class="tableTitleButton"
                   @click="exportFile">导出</el-button>
      </el-row>
      <el-row class="tableContent">
        <dt-table ref="tableRef"
                  :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig="tableConfig"
                  :paginationConfig="paginationConfig"
                  @getList="getList"
                  @queryTableList="queryTableList">
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="16">
                  <el-button @click="handleClick(scope.row)"
                             type="text"
                             size="medium">详情</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <el-dialog title="记录详情"
               append-to-body
               :visible.sync="dialogVisible"
               center>
      <el-form :model="detailForm"
               label-position="left"
               label-width="110px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="编号">
              <el-input :disabled="true"
                        v-model="detailForm.userCode"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="巡检员">
              <el-input :disabled="true"
                        v-model="detailForm.userName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="12">
            <el-form-item label="巡检组">
              <el-input :disabled="true"
                        v-model="detailForm.inspectionGroupName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签退状态">
              <el-input :disabled="true"
                        v-model="detailForm.signOutStatusDesc"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="12">
            <el-form-item label="签到状态">
              <el-input :disabled="true"
                        v-model="detailForm.signInStatusDesc"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签退时间">
              <el-input :disabled="true"
                        v-model="detailForm.signOutTime"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="12">
            <el-form-item label="签到时间">
              <el-input :disabled="true"
                        v-model="detailForm.signInTime"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签退备注">
              <el-input :disabled="true"
                        v-model="detailForm.signOutdec"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="12">
            <el-form-item label="签到备注">
              <el-input :disabled="true"
                        v-model="detailForm.signIndec"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex"
                justify="end">
          <el-col :span="12"
                  class="dt-button-box">
            <el-button type="primary"
                       @click="handleBack">返回</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from "@/components/table.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: "userCode",
          label: "编号",
        },
        {
          prop: "userName",
          label: "巡检员",
        },
        {
          prop: "inspectionGroupName",
          label: "巡检组",
        },
        {
          prop: "signInStatusDesc",
          label: "签到状态",
        },
        {
          prop: "signInTime",
          label: "签到时间",
        },
        {
          prop: "signOutStatusDesc",
          label: "交班/签退状态",
        },
        {
          prop: "signOutTime",
          label: "交班/签退时间",
        },
      ],
      tableList: {
        list: [],
      },
      searchForm: {},
      pageNum: 1,
      pageSize: 15,
      signInTime: [],// 签到时间
      signOutTime: [],// 签退时间
      inspectionList: [],// 巡检组列表
      options: [
        { label: "未上班", value: 0 },
        { label: "已上班", value: 1 },
      ],
      options2: [
        { label: "未下班", value: 0 },
        { label: "已下班", value: 1 },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick (picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: "",
      value2: "",
      dialogVisible: false, //弹出框
      detailForm: {}, //详情
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.queryInspectionList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryTableList();
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 导出按钮
    exportFile () {
      this.$axios.get(this.$downloadBaseUrl + "attendance/download", {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        }
      }).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "考勤记录.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
      })
    },
    // 初始化渲染
    queryTableList () {
      if (this.signInTime.length) {
        this.searchForm.signInStartTime = this.signInTime[0];
        this.searchForm.signInEndTime = this.signInTime[1];
      }
      if (this.signOutTime.length) {
        this.searchForm.signOutStartTime = this.signOutTime[0];
        this.searchForm.signOutEndTime = this.signOutTime[1];
      }
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$attendanceRecord.queryAttendanceList(this.searchForm).then((res) => {
        this.tableList.list = res.resultEntity.list;
        this.paginationConfig.total = res.resultEntity.total;
      });
    },
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList()
    },
    //查询
    onSubmit () {
      this.pageNum = 1;
      this.listMap.pageNum = 1
      this.queryTableList()
    }, //
    // 重置
    resetForm () {
      this.searchForm = {}
      this.pageNum = 1;
      this.listMap.pageNum = 1
      this.signInTime = [];
      this.signOutTime = [];
      this.queryTableList();
    },
    // 详情
    handleClick (row) {
      this.detailForm = row;
      this.dialogVisible = true;
    },
    // 巡检组
    queryInspectionList () {
      let info = {
        columnName: ["inspection_group_id", "inspection_group_name"],
        tableName: "tb_inspection_group",
      };
      this.$queryDict.queryDict(info).then((res) => {
        this.inspectionList = res.resultEntity;
      });
    },
    //返回
    handleBack () {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang='scss' scoped>
$topSelectHeight: 185px;
//@import url(); 引入公共css类
// 最外层div
.mainbody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 110px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
</style>